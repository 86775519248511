// 完整版菜单比较多，将 rank 抽离出来，在此方便维护

const home = 0, // 平台规定只有 home 路由的 rank 才能为 0 ，所以后端在返回 rank 的时候需要从 1 开始哦
  project = 1,
  user = 2,
  folder = 3,
  report = 4,
  frame = 8,
  error = 9,
  permission = 11,
  system = 12,
  formdesign = 15,
  chainup = 16
export {
  home,
  project,
  user,
  folder,
  report,
  frame,
  error,
  permission,
  system,
  formdesign,
  chainup
}

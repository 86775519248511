import { $t } from '@/plugins/i18n'
import { folder } from '@/router/enums'

export default {
  path: '/folder',
  redirect: '/folder/index',
  meta: {
    icon: 'ep:folder',
    title: $t('menus.hsfolder'),
    rank: folder,
    showLink: false // 隐藏菜单
  },
  children: [
    {
      path: '/folder/index',
      name: 'Folder',
      component: () => import('@/views/folder/index.vue'),
      meta: {
        title: $t('menus.hsfolder')
      }
    }
  ]
} as RouteConfigsTable

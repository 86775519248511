import { chainup } from '@/router/enums'
import { storageKey, type DataInfo } from '@/utils/auth'
import { storageLocal } from '@pureadmin/utils'

const userInfo = storageLocal().getItem<DataInfo<number>>(storageKey)
export default {
  path: '/chainup',
  redirect: '/chainup/entity/index',
  meta: {
    icon: 'fa:gg',
    title: 'ChainUp',
    rank: chainup,
    showLink:
      userInfo?.permissions?.chainUp ||
      import.meta.env.MODE === 'development' ||
      import.meta.env.MODE === 'test' // 隐藏菜单
  },
  children: [
    {
      path: '/chainup/entity/index',
      name: 'chainupEntityList',
      component: () => import('@/views/chainup/entity/list/index.vue'),
      meta: {
        icon: 'fa:bookmark',
        title: '实体列表'
      }
    },
    {
      path: '/chainup/entity/account',
      name: 'chainupEntityAccountList',
      component: () => import('@/views/chainup/entity/account/index.vue'),
      meta: {
        icon: 'fa:drivers-license',
        title: '账户列表',
        showLink: false // 隐藏菜单
      }
    },
    {
      path: '/chainup/entity/reports',
      name: 'chainupEntityReports',
      component: () => import('@/views/chainup/entity/reports/index.vue'),
      meta: {
        icon: 'fa:table',
        title: '报表',
        showLink: false // 隐藏菜单
      }
    },
    {
      path: '/chainup/user/index',
      name: 'chainupUserList',
      component: () => import('@/views/chainup/user/index.vue'),
      meta: {
        icon: 'fa:user',
        title: '用户列表'
      }
    }
  ]
} as RouteConfigsTable

import Axios, {
  AxiosInstance,
  AxiosRequestConfig,
  CustomParamsSerializer
} from 'axios'
import { storageSession, storageLocal } from '@pureadmin/utils'
import {
  ElvenHttpError,
  RequestMethods,
  ElvenHttpResponse,
  ElvenHttpRequestConfig
} from './types.d'
import { stringify } from 'qs'
import NProgress from '../progress'
import { message } from '@/utils/message'
import { storageKey, getToken, formatToken, removeToken } from '@/utils/auth'
// import { useUserStoreHook } from '@/store/modules/user'

// 相关配置请参考：www.axios-js.com/zh-cn/docs/#axios-request-config-1
const defaultConfig: AxiosRequestConfig = {
  baseURL: import.meta.env.VITE_BASE_URL,
  // 请求超时时间
  timeout: 10000,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
  // 数组格式参数序列化（https://github.com/axios/axios/issues/5142）
  paramsSerializer: {
    serialize: stringify as unknown as CustomParamsSerializer
  }
}

class ElvenHttp {
  constructor() {
    this.httpInterceptorsRequest()
    this.httpInterceptorsResponse()
  }

  /** token过期后，暂存待执行的请求 */
  private static requests = []

  /** 防止重复刷新token */
  private static isRefreshing = false

  /** 初始化配置对象 */
  private static initConfig: ElvenHttpRequestConfig = {}

  /** 保存当前Axios实例对象 */
  private static axiosInstance: AxiosInstance = Axios.create(defaultConfig)

  /** 重连原始请求 */
  private static retryOriginalRequest(config: ElvenHttpRequestConfig) {
    return new Promise(resolve => {
      ElvenHttp.requests.push((token: string) => {
        config.headers['Authorization'] = formatToken(token)
        resolve(config)
      })
    })
  }

  /** 请求拦截 */
  private httpInterceptorsRequest(): void {
    ElvenHttp.axiosInstance.interceptors.request.use(
      async (config: ElvenHttpRequestConfig) => {
        // 开启进度条动画
        NProgress.start()
        // 优先判断post/get等方法是否传入回掉，否则执行初始化设置等回掉
        if (typeof config.beforeRequestCallback === 'function') {
          config.beforeRequestCallback(config)
          return config
        }
        if (ElvenHttp.initConfig.beforeRequestCallback) {
          ElvenHttp.initConfig.beforeRequestCallback(config)
          return config
        }
        /** 请求白名单，放置一些不需要token的接口（通过设置请求白名单，防止token过期后再请求造成的死循环问题） */
        const whiteList = [
          '/refreshToken',
          '/login',
          '/admin/generate/google/authenticator/secret',
          '/admin/bind/google/authenticator/secret'
        ]
        return whiteList.some(v => config.url.indexOf(v) > -1)
          ? config
          : new Promise(resolve => {
              const data = getToken()
              if (data) {
                const now = new Date().getTime()
                const expired = parseInt(data.expires) - now / 1000 <= 0
                if (expired) {
                  if (!ElvenHttp.isRefreshing) {
                    removeToken()
                    storageSession().clear()
                    storageLocal().removeItem(storageKey)
                    message('登录失效', { type: 'error' })
                    window.open('/login', '_self')
                    // ElvenHttp.isRefreshing = true
                    // // token过期刷新
                    // useUserStoreHook()
                    //   .handRefreshToken({ refreshToken: data.refreshToken })
                    //   .then(res => {
                    //     const token = res.data.accessToken
                    //     config.headers['Authorization'] = formatToken(token)
                    //     ElvenHttp.requests.forEach(cb => cb(token))
                    //     ElvenHttp.requests = []
                    //   })
                    //   .finally(() => {
                    //     ElvenHttp.isRefreshing = false
                    //   })
                  }
                  resolve(ElvenHttp.retryOriginalRequest(config))
                } else {
                  config.headers['Authorization'] = formatToken(
                    data.accessToken
                  )
                  resolve(config)
                }
              } else {
                removeToken()
                storageSession().clear()
                storageLocal().removeItem(storageKey)
                message('登录失效', { type: 'error' })
                window.open('/login', '_self')
                resolve(config)
              }
            })
      },
      error => {
        return Promise.reject(error)
      }
    )
  }

  /** 响应拦截 */
  private httpInterceptorsResponse(): void {
    const instance = ElvenHttp.axiosInstance
    instance.interceptors.response.use(
      (response: ElvenHttpResponse) => {
        const $config = response.config
        // 关闭进度条动画
        NProgress.done()
        // 优先判断post/get等方法是否传入回掉，否则执行初始化设置等回掉
        if (typeof $config.beforeResponseCallback === 'function') {
          $config.beforeResponseCallback(response)
          return response.data
        }
        if (ElvenHttp.initConfig.beforeResponseCallback) {
          ElvenHttp.initConfig.beforeResponseCallback(response)
          return response.data
        }
        return response.data
      },
      (error: ElvenHttpError) => {
        const $error = error
        $error.isCancelRequest = Axios.isCancel($error)
        // 关闭进度条动画
        NProgress.done()

        // 获取状态码数据
        if (error.response.status === 400 || error.response.status === 401) {
          message(error.response.data.data.message, {
            type: 'error'
          })
          if (error.response.status === 401) {
            removeToken()
            storageSession().clear()
            storageLocal().removeItem(storageKey)
            window.open('/login', '_self')
          }
          return Promise.reject(error.response.data.data)
        }
        // 所有的响应异常 区分来源为取消请求/非取消请求
        return Promise.reject($error)
      }
    )
  }

  /** 通用请求工具函数 */
  public request<T = ResponseData>(
    method: RequestMethods,
    url: string,
    param?: AxiosRequestConfig,
    axiosConfig?: ElvenHttpRequestConfig
  ): Promise<T> {
    const config = {
      method,
      url,
      ...param,
      ...axiosConfig
    } as ElvenHttpRequestConfig

    // 单独处理自定义请求/响应回掉
    return new Promise((resolve, reject) => {
      ElvenHttp.axiosInstance
        .request(config)
        .then((response: undefined) => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** 单独抽离的post工具函数 */
  public post<T, P>(
    url: string,
    params?: AxiosRequestConfig<T>,
    config?: ElvenHttpRequestConfig
  ): Promise<P> {
    return this.request<P>('post', url, params, config)
  }

  /** 单独抽离的get工具函数 */
  public get<T, P>(
    url: string,
    params?: AxiosRequestConfig<T>,
    config?: ElvenHttpRequestConfig
  ): Promise<P> {
    return this.request<P>('get', url, params, config)
  }
}

export const http = new ElvenHttp()

import { store } from '@/store'
import { userType } from '../types'
import { routerArrays } from '@/layout/types'
import { router, resetRouter } from '@/router'
import { storageLocal } from '@pureadmin/utils'
import { getLogin, refreshTokenApi } from '@/api/user'
import { UserResult, RefreshTokenResult } from '@/api/user'
import { useMultiTagsStoreHook } from '@/store/modules/multiTags'
import { type DataInfo, setToken, removeToken, storageKey } from '@/utils/auth'

export const useUserStore = defineStore({
  id: 'elven-user',
  state: (): userType => ({
    // 用户名
    username:
      storageLocal().getItem<DataInfo<number>>(storageKey)?.username ?? '',
    // 页面级别权限
    roles: storageLocal().getItem<DataInfo<number>>(storageKey)?.roles ?? [],
    // 前端生成的验证码（按实际需求替换）
    verifyCode: '',
    // 判断登录页面显示哪个组件（0：登录（默认）
    currentPage: 0
  }),
  actions: {
    /** 存储用户名 */
    SET_USERNAME(username: string) {
      this.username = username
    },
    /** 存储角色 */
    SET_ROLES(roles: Array<string>) {
      this.roles = roles
    },
    /** 存储前端生成的验证码 */
    SET_VERIFYCODE(verifyCode: string) {
      this.verifyCode = verifyCode
    },
    /** 存储登录页面显示哪个组件 */
    SET_CURRENTPAGE(value: number) {
      this.currentPage = value
    },
    /** 登入 */
    async loginByUsername(data) {
      return new Promise<UserResult>((resolve, reject) => {
        getLogin(data)
          .then(res => {
            if (res) {
              setToken(res.data)
              resolve(res)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /** 前端登出（不调用接口） */
    logOut() {
      this.username = ''
      this.roles = []
      removeToken()
      useMultiTagsStoreHook().handleTags('equal', [...routerArrays])
      resetRouter()
      router.push('/login')
    },
    /** 刷新`token` */
    async handRefreshToken(data) {
      return new Promise<RefreshTokenResult>((resolve, reject) => {
        refreshTokenApi(data)
          .then(data => {
            if (data) {
              setToken(data.data)
              resolve(data)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
})

export function useUserStoreHook() {
  return useUserStore(store)
}

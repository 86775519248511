import { project } from '@/router/enums'
import { storageKey, type DataInfo } from '@/utils/auth'
import { storageLocal } from '@pureadmin/utils'

const userInfo = storageLocal().getItem<DataInfo<number>>(storageKey)

export default {
  path: '/project',
  redirect: '/project/index',
  meta: {
    icon: 'ep:coin',
    title: '项目管理',
    rank: project,
    showLink: userInfo?.permissions?.project
  },
  children: [
    {
      path: '/project/index',
      name: 'Project',
      component: () => import('@/views/project/list/index.vue'),
      meta: {
        icon: 'fa:bitcoin',
        title: '项目列表'
      }
    },
    {
      path: '/project/entity/index',
      name: 'Entity',
      component: () => import('@/views/project/entity/list/index.vue'),
      meta: {
        icon: 'fa:bookmark',
        title: '实体列表'
      }
    },
    {
      path: '/project/entity/account',
      name: 'EntityAccountList',
      component: () => import('@/views/project/entity/account/index.vue'),
      meta: {
        icon: 'fa:bookmark',
        title: '账户列表',
        showLink: false // 隐藏菜单
      }
    },
    {
      path: '/project/entity/permission/index',
      name: 'Entity-permission',
      component: () => import('@/views/project/entity/permission/index.vue'),
      meta: {
        icon: 'ep:lollipop',
        title: '权限管理',
        showLink: false // 隐藏菜单
      }
    },
    {
      path: '/project/entity/systemlog',
      name: 'Entity-system-log',
      component: () => import('@/views/project/entity/systemlog/index.vue'),
      meta: {
        icon: 'ep:log',
        title: '系统日志',
        showLink: false // 隐藏菜单
      }
    }
  ]
} as RouteConfigsTable

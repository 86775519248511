import { $t } from '@/plugins/i18n'
import { formdesign } from '@/router/enums'
const IFrame = () => import('@/layout/frameView.vue')

export default {
  path: '/formDesign',
  redirect: '/formDesign/index',
  meta: {
    icon: 'terminalWindowLine',
    title: $t('menus.hsFormDesign'),
    rank: formdesign,
    showLink: false // 隐藏菜单
  },
  children: [
    {
      path: '/formDesign/index',
      name: 'FormDesign',
      component: IFrame,
      meta: {
        title: $t('menus.hsFormDesign'),
        frameSrc: 'http://120.92.142.115:81/vform3/'
      }
    }
  ]
} as RouteConfigsTable

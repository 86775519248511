import { http } from '@/utils/http'

export type UserResult = {
  status: string
  data: {
    /** 用户名 */
    username: string
    /** 当前登陆用户的角色 */
    roles: Array<string>
    /** `token` */
    accessToken: string
    /** 用于调用刷新`accessToken`的接口时所需的`token` */
    refreshToken: string
    /** `accessToken`的过期时间（格式'xxxx/xx/xx xx:xx:xx'） */
    expires: Date
    /** 菜单权限 */
    permissions: {
      project: boolean
      chainUp: boolean
      user: boolean
    }
  }
}

export type RefreshTokenResult = {
  status: string
  data: {
    /** `token` */
    accessToken: string
    /** 用于调用刷新`accessToken`的接口时所需的`token` */
    refreshToken: string
    /** `accessToken`的过期时间（格式'xxxx/xx/xx xx:xx:xx'） */
    expires: Date
    permissions: any
  }
}

export type GenerateGoogleCodeResult = {
  status: string
  data: {
    /** `token` */
    qrcodeUrl: string
    name: string
  }
}

export type bindGoogleCodeResult = {
  status: string
  data: boolean
}

/** 登录 */
export const getLogin = (data?: object) => {
  return http.request<UserResult>('post', '/admin/login', { data })
}

/** 刷新token */
export const refreshTokenApi = (data?: object) => {
  return http.request<RefreshTokenResult>('post', '/refreshToken', { data })
}

// 生成谷歌验证码
export const generateGoogleCode = (data?: object) => {
  return http.request<GenerateGoogleCodeResult>(
    'post',
    '/admin/generate/google/authenticator/secret',
    { data }
  )
}

// 绑定谷歌验证码
export const bindGoogleCode = (data?: object) => {
  return http.request<bindGoogleCodeResult>(
    'post',
    '/admin/bind/google/authenticator/secret',
    { data }
  )
}

// 创建用户
export const createUser = (data?: object) => {
  return http.request('post', '/user', { data })
}

export const updateUser = (userId: number, data?: object) => {
  return http.request('put', `/user/${userId}`, { data })
}

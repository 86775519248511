import { $t } from '@/plugins/i18n'
import { user } from '@/router/enums'
import { storageKey, type DataInfo } from '@/utils/auth'
import { storageLocal } from '@pureadmin/utils'

const userInfo = storageLocal().getItem<DataInfo<number>>(storageKey)
export default {
  path: '/user',
  redirect: '/user/list/index',
  meta: {
    icon: 'ep:user',
    title: $t('menus.hsUser'),
    rank: user,
    showLink: userInfo?.permissions?.user
  },
  children: [
    {
      path: '/user/list/index',
      name: 'User',
      component: () => import('@/views/user/list/index.vue'),
      meta: {
        icon: 'ep:avatar',
        title: $t('menus.hsUserList')
      }
    }
  ]
} as RouteConfigsTable

import { $t } from '@/plugins/i18n'
import { report } from '@/router/enums'

export default {
  path: '/data',
  redirect: '/data/import',
  meta: {
    icon: 'ep:tickets',
    title: $t('menus.data'),
    rank: report,
    showLink: false // 隐藏菜单
  },
  children: [
    {
      path: '/report/import',
      name: 'DataImport',
      component: () => import('@/views/data/import/index.vue'),
      meta: {
        icon: 'ep:document',
        title: $t('menus.dataImport'),
        disabled: false
      }
    }
  ]
} as RouteConfigsTable

export default {
  path: '/platform',
  redirect: '/platform/index',
  meta: {
    icon: 'ep:coin',
    title: '平台管理',
    rank: 1,
    showLink: false // 隐藏菜单
  },
  children: [
    {
      path: '/platform/index',
      name: 'Platform',
      component: () => import('@/views/platform/list/index.vue'),
      meta: {
        showLink: false, // 隐藏菜单
        icon: 'fa:apple',
        title: '平台列表'
      }
    }
  ]
} as RouteConfigsTable

import { $t } from '@/plugins/i18n'
import { report } from '@/router/enums'

export default {
  path: '/report',
  redirect: '/report/configuration/index',
  meta: {
    icon: 'ep:tickets',
    title: $t('menus.hsreport'),
    rank: report,
    showLink: false // 隐藏菜单
  },
  children: [
    {
      path: '/report/configuration/index',
      name: 'ReportConfiguration',
      component: () => import('@/views/report/configuration/index.vue'),
      meta: {
        icon: 'ep:document',
        title: $t('menus.hsreportConfiguration'),
        disabled: true
      }
    },
    {
      path: '/report/project/index',
      name: 'ProjectList',
      component: () => import('@/views/report/project/index.vue'),
      meta: {
        icon: 'fa:bitcoin',
        title: $t('menus.hsprojectManagement'),
        disabled: true
      }
    },
    {
      path: '/report/coin/index',
      name: 'CoinList',
      component: () => import('@/views/report/coin/index.vue'),
      meta: {
        icon: 'fa-solid:coins',
        title: $t('menus.hscoinManagement'),
        disabled: true
      }
    }
  ]
} as RouteConfigsTable

buttons:
  hsLoginOut: 退出系统
  hsfullscreen: 全屏
  hsexitfullscreen: 退出全屏
  hsrefreshRoute: 刷新路由
  hslogin: 登录
  hsadd: 新增
  hsedit: 编辑
  hsmark: 标记/取消
  hscancel: 取消
  hssave: 保存
  hssearch: 搜索
  hsexpendAll: 全部展开
  hscollapseAll: 全部折叠
  hssystemSet: 打开项目配置
  hsdelete: 删除
  hsreload: 重新加载
  hscloseCurrentTab: 关闭当前标签页
  hscloseLeftTabs: 关闭左侧标签页
  hscloseRightTabs: 关闭右侧标签页
  hscloseOtherTabs: 关闭其他标签页
  hscloseAllTabs: 关闭全部标签页
  hswholeFullScreen: 全屏
  hswholeExitFullScreen: 退出全屏
  hscontentFullScreen: 内容区全屏
  hscontentExitFullScreen: 内容区退出全屏
menus:
  hshome: 首页
  hslogin: 登录
  hsempty: 无Layout页
  hsproject: 项目管理
  hsprojectList: 项目列表
  hsPlatform: 平台管理
  hsPlatformList: 平台列表
  hsfolder: 数据源管理
  hsreport: 报表管理
  hsreportConfiguration: 报表配置
  hsprojectManagement: 项目管理
  hscoinManagement: 币种管理
  hssysManagement: 系统管理
  hsUser: 用户管理
  hsUserList: 用户列表
  hsRole: 角色管理
  hsDept: 部门管理
  hsabnormal: 异常页面
  hsfourZeroFour: '404'
  hsfourZeroOne: '403'
  hsFive: '500'
  hscomponents: 组件
  permission: 权限管理
  permissionPage: 页面权限
  permissionButton: 按钮权限
  hsAble: 功能
  hsFormDesign: 表单设计器
  data: 导入管理
  dataImport: 数据导入
status:
  hsLoad: 加载中...
login:
  username: 账号
  password: 密码
  verifyCode: 验证码
  remember: 记住密码
  sure: 确认密码
  login: 登录
  phone: 手机号码
  smsVerifyCode: 短信验证码
  back: 返回
  test: 模拟测试
  tip: 扫码后点击"确认"，即可完成登录
  definite: 确定
  loginSuccess: 登录成功
  tickPrivacy: 请勾选隐私政策
  readAccept: 我已仔细阅读并接受
  privacyPolicy: 《隐私政策》
  getVerifyCode: 获取验证码
  info: 秒后重新获取
  usernameReg: 请输入账号
  passwordReg: 请输入密码
  verifyCodeReg: 请输入验证码
  verifyCodeCorrectReg: 请输入正确的验证码
  verifyCodeSixReg: 请输入6位数字验证码
  phoneReg: 请输入手机号码
  phoneCorrectReg: 请输入正确的手机号码格式
  passwordRuleReg: 密码格式应为8-32位数字、字母、符号的任意两种组合
  passwordSureReg: 请输入确认密码
  passwordDifferentReg: 两次密码不一致!
  passwordUpdateReg: 修改密码成功
title:
  tiProjectName: 项目名称
  tiChain: 链
  tiAddress: 地址
  tiAddressName: 地址名称
  tiDescription: 描述

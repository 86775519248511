buttons:
  hsLoginOut: LoginOut
  hsfullscreen: FullScreen
  hsexitfullscreen: ExitFullscreen
  hsrefreshRoute: RefreshRoute
  hslogin: Login
  hsadd: Add
  hsedit: Edit
  hsmark: Mark/Cancel
  hscancel: Cancel
  hssave: Save
  hssearch: Search
  hsexpendAll: Expand All
  hscollapseAll: Collapse All
  hssystemSet: Open ProjectConfig
  hsdelete: Delete
  hsreload: Reload
  hscloseCurrentTab: Close CurrentTab
  hscloseLeftTabs: Close LeftTabs
  hscloseRightTabs: Close RightTabs
  hscloseOtherTabs: Close OtherTabs
  hscloseAllTabs: Close AllTabs
  hswholeFullScreen: FullScreen
  hswholeExitFullScreen: ExitFull
  hscontentFullScreen: Content FullScreen
  hscontentExitFullScreen: Content ExitFullScreen
menus:
  hshome: Home
  hslogin: Login
  hsempty: Empty Page
  hsfolder: Data Source Manage
  hsproject: Project Manage
  hsprojectList: Project List
  hsPlatform: Platform Manage
  hsPlatformList: Platform List
  hsreport: Report Manage
  hsreportConfiguration: Report Configuration
  hsprojectManagement: Project Manage
  hscoinManagement: Coin Manage
  hssysManagement: System Manage
  hsUser: User Manage
  hsUserList: User List
  hsRole: Role Manage
  hsDept: Dept Manage
  hsabnormal: Abnormal Page
  hsfourZeroFour: '404'
  hsfourZeroOne: '403'
  hsFive: '500'
  hscomponents: Components
  hsmap: Map Components
  permission: Permission Manage
  permissionPage: Page Permission
  permissionButton: Button Permission
  hsAble: Able
  hsFormDesign: Form Design
status:
  hsLoad: Loading...
login:
  username: Username
  password: Password
  verifyCode: VerifyCode
  remember: Remember Password
  sure: Sure Password
  login: Login
  phone: Phone
  smsVerifyCode: SMS VerifyCode
  back: Back
  test: Mock Test
  tip: After scanning the code, click "Confirm" to complete the login
  definite: Definite
  loginSuccess: Login Success
  tickPrivacy: Please tick Privacy Policy
  readAccept: I have read it carefully and accept
  privacyPolicy: Privacy Policy
  getVerifyCode: Get VerifyCode
  info: Seconds
  usernameReg: Please enter username
  passwordReg: Please enter password
  verifyCodeReg: Please enter verify code
  verifyCodeCorrectReg: Please enter correct verify code
  verifyCodeSixReg: Please enter a 6-digit verify code
  phoneReg: Please enter the phone
  phoneCorrectReg: Please enter the correct phone number format
  passwordRuleReg: The password format should be any combination of 8-32 digits
  passwordSureReg: Please enter confirm password
  passwordDifferentReg: The two passwords do not match!
  passwordUpdateReg: Password has been updated
title:
  tiProjectName: Project Name
  tiChain: Chain
  tiAddress: Address
  tiAddressName: AddressName
  tiDescription: Description
